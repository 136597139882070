.skills_page{
  flex-direction: column;
  margin-top: 3em;
  height: 85vh;
  justify-content: center;
  align-items: center;
}
.skills__list {
  max-width: 550px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__list-item {
  margin: 0.5em;
}